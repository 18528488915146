import { NotificationGroupListModel, NotificationQFModel, NotificationSettings } from 'ngx-q360-lib';
import { notificationsQuery } from '@core/helpers/query.helper';

export interface NotificationStateModel {
  notifications: NotificationGroupListModel;
  notificationsQueryFilter: NotificationQFModel;
  error: string | null;
  loading: boolean;
  loadingGroupNotifications: boolean;
  settings: NotificationSettings | null;
}

export const defaultState: NotificationStateModel = {
  notifications: {
    items: [],
    totalCount: 0,
    unseenCount: 0,
    page: 0,
    pageSize: 10,
  },
  notificationsQueryFilter: { ...notificationsQuery },
  error: null,
  loading: false,
  loadingGroupNotifications: false,
  settings: null,
};
